<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-6">
        <h1>
          Quotations converted for Week {{ week }} {{ year }}
        </h1>
      </v-col>
      <v-col class="col-2">
        <v-text-field
          placeholder="Sales person initials i.e. KB"
          style="margin-top: 0; padding-top: 0;"
          v-model="salesPerson"></v-text-field>
      </v-col>
      <v-col class="col-4">
        <v-btn
          @click="showPicker = true"
          class="float-right"
          small>View calendar</v-btn>
        <v-btn
          @click="changeWeek(2)"
          class="float-right ml-2 mr-2"
          small>Next Week</v-btn>
        <v-btn
          @click="changeWeek(1)"
          class="float-right"
          small>Prev Week</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="showPicker">
      <v-col>
        <v-date-picker v-model="weekDate"></v-date-picker>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          Total quotations: {{ totalQuotations }},
          Total converted {{ totalConverted }},
          Total lost {{ totalLost }},
          Total pending {{ totalPending }}
        </p>
        <p>
          Converted = {{ percentageConverted }}%,
          Lost = {{ percentageLost }}%
          Pending = {{ percentagePending }}%
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-left">No</th>
              <th class="text-left">Customer</th>
              <th class="text-left">Date</th>
              <th class="text-left">Site</th>
              <th class="text-left">Value</th>
              <th class="text-left">By</th>
              <th class="text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="text-left" colspan="7">Converted Quotations</th>
            </tr>
            <tr v-for="(quotation, index) in convertedQuotations" :key="`cq_${index}`">
              <td>{{ quotation.jobNumber }} {{ quotation.expired }}</td>
              <td>{{ quotation.customer }}</td>
              <td>{{ quotation.date | tinyDate }}</td>
              <td>{{ quotation.site }}</td>
              <td>
                <span v-if="quotation.value > quotation.minChargeAmount">
                  {{ quotation.value | priceInPounds | currency }}
                </span>
                <span v-else>
                  {{ quotation.minChargeAmount | priceInPounds | currency }}
                </span>
              </td>
              <td>{{ quotation.quotedByInitials }}</td>
              <td>
                <router-link custom v-slot="{ navigate }" :to="`/quotations/view/${quotation.id}`">
                  <v-btn
                    x-small
                    @click="navigate"
                    @keypress.enter="navigate"
                    role="link">
                    View
                  </v-btn>
                </router-link>
              </td>
            </tr>
            <tr>
              <th class="text-left" colspan="7">Pending Quotations</th>
            </tr>
            <tr v-for="(quotation, index) in pendingQuotations" :key="`q_${index}`">
              <td>{{ quotation.jobNumber }} {{ quotation.expired }}</td>
              <td>{{ quotation.customer }}</td>
              <td>{{ quotation.date | tinyDate }}</td>
              <td>{{ quotation.site }}</td>
              <td>
                <span v-if="quotation.value > quotation.minChargeAmount">
                  {{ quotation.value | priceInPounds | currency }}
                </span>
                <span v-else>
                  {{ quotation.minChargeAmount | priceInPounds | currency }}
                </span>
              </td>
              <td>{{ quotation.quotedByInitials }}</td>
              <td>
                <router-link custom v-slot="{ navigate }" :to="`/quotations/view/${quotation.id}`">
                  <v-btn
                    x-small
                    @click="navigate"
                    @keypress.enter="navigate"
                    role="link">
                    View
                  </v-btn>
                </router-link>
              </td>
            </tr>
            <tr>
              <th class="text-left" colspan="7">Lost Quotations</th>
            </tr>
            <tr v-for="(quotation, index) in lostQuotations" :key="`qt_${index}`">
              <td>{{ quotation.jobNumber }} {{ quotation.expired }}</td>
              <td>{{ quotation.customer }}</td>
              <td>{{ quotation.date | tinyDate }}</td>
              <td>{{ quotation.site }}</td>
              <td>
                <span v-if="quotation.value > quotation.minChargeAmount">
                  {{ quotation.value | priceInPounds | currency }}
                </span>
                <span v-else>
                  {{ quotation.minChargeAmount | priceInPounds | currency }}
                </span>
              </td>
              <td>{{ quotation.quotedByInitials }}</td>
              <td>
                <router-link custom v-slot="{ navigate }" :to="`/quotations/view/${quotation.id}`">
                  <v-btn
                    x-small
                    @click="navigate"
                    @keypress.enter="navigate"
                    role="link">
                    View
                  </v-btn>
                </router-link>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'ReportsWeeklyPay',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      showPicker: false,
      lostQuotations: [],
      convertedQuotations: [],
      pendingQuotations: [],
      totalQuotations: 0,
      totalConverted: 0,
      percentageConverted: 0,
      totalPending: 0,
      totalLost: 0,
      percentageLost: 0,
      percentagePending: 0,
      salesPerson: '',
      week: 0,
      year: 0,
      weekDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    };
  },
  watch: {
    weekDate() {
      this.showPicker = false;
      this.updateWeek();
    },
    salesPerson() {
      if (this.salesPerson.length > 1) {
        this.getQuotationsConverted();
      }
    }
  },
  methods: {
    changeWeek(direction) {
      if (direction === 1) {
        if (this.week > 1) {
          this.week = parseInt(this.week) - 1;
        } else {
          this.week = 52;
          this.year = parseInt(this.year) - 1;
        }
      } else {
        if (this.week < 52) {
          this.week = parseInt(this.week) + 1;
        } else {
          this.week = 1;
          this.year = parseInt(this.year) + 1;
        }
      }
      this.getQuotationsConverted();
    },
    updateWeek() {
      const currentDate = new Date(this.weekDate);
      const startDate = new Date(currentDate.getFullYear(), 0, 1);
      const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
      this.week = Math.ceil(days / 7);
      this.year = currentDate.getFullYear();
      this.getQuotationsConverted();
    },
    getQuotationsConverted() {
      axios.get(`/quotations/getPercentageConverted/${this.week}/${this.year}/${this.salesPerson}.json?token=${this.token}`)
      .then((response) => {
        this.lostQuotations = response.data.lostQuotations;
        this.convertedQuotations = response.data.convertedQuotations;
        this.pendingQuotations = response.data.pendingQuotations;
        this.totalQuotations = response.data.totalQuotations;
        this.totalConverted = response.data.totalConverted;
        this.percentageConverted = Math.round((100 / this.totalQuotations) * this.totalConverted);
        this.totalLost = response.data.totalLost;
        this.percentageLost = Math.round((100 / this.totalQuotations) * this.totalLost);
        this.totalPending = response.data.totalPending;
        this.percentagePending = Math.round((100 / this.totalQuotations) * this.totalPending);
        this.week = response.data.week;
        this.year = response.data.year;
      });
    },
  },
  mounted() {
    this.updateWeek();
  },
};
</script>
